import React, { Fragment } from "react"
import { ImageBackground } from "react-native"
import { useTheme, styled, Headline } from "unikit"

import { ServiceItem } from "../components"

import Strategy from "../images/service2.jpg"
import Design from "../images/ux3.jpg"
import Dev from "../images/coding.jpg"

const Head = styled.View(({ theme }) => ({
  backgroundColor: "dark",
  paddingVertical: 60,
  width: "100%",
  alignItems: "center",
  paddingTop: 100,
}))

const Wrapper = styled.View(({ theme }) => ({
  margin: "0 auto",
  maxWidth: theme.wrapperWidth,
  width: "80%",
}))

export default ({ data }) => {
  const theme = useTheme()

  return (
    <Fragment>
      <Head>
        <Wrapper>
          <Headline style={{ color: "#FFF", textAlign: "center" }} animate>
            What we can do
          </Headline>
        </Wrapper>
      </Head>
      <ServiceItem
        image={Strategy}
        delay={250}
        title="Beratung"
        text="Wir richten das Denken, die Ziele und unser Team auf Ihre Produkt aus. Dies gewährleistet Effizienz und verlässliche Ergebnisse."
      />
      <ServiceItem
        image={Design}
        delay={500}
        title="UI/UX Design"
        text="Wir alle wissen, wie es ist, von einer Marke digital frustriert zu sein. Deshalb sind wir bestrebt, ein positives Erlebnis für Ihre Marke zu schaffen - Kunden zu begeistern, Loyalität und Loyalität steigern."
        reverse={theme.window && theme.window.width > 1100 ? true : false}
      />
      <ServiceItem
        image={Dev}
        delay={0}
        title="Entwicklung"
        text="Wir entwickeln mit moderner und zugunftssicherer Technologie Cross-Plattform Elebnisse die Ihre Kunden begeistern und nicht frustrieren."
      />
    </Fragment>
  )
}
